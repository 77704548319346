export default {
  // Base url path
  // baseUrl: 'http://localhost:4000/tickets_api', // MI MAQUINA
  // baseUrl: 'http://192.168.150.62:4000/tickets', // Sebastian Maquina
  // baseUrl: 'http://localhost:5000/tickets_api', // Andrei Maquina
  baseUrl: 'https://api.rocinantehealthtech.com/tickets_api',
  // Endpoints
  loginEndpoint: '/api/v1/auth/login',
  resetPassword: '/api/v1/auth/change-password',
  verifyAuthyCodeEndpoint: '/api/v1/users/verify',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // Endpoints
  signUp: '/api/v1/auth/signup',
  updateUser: '/api/v1/auth/update-user',
  createUser: '/api/v1/auth/register-account',
  deleteUser: '/api/v1/auth/delete-user',
  getUserById: '/api/v1/auth/get-user',
  getUsers: '/api/v1/auth/get-users',

  // Endpoints Tickets
  getTickets: '/api/v1/tickets',
  getTicketStatusComment: '/api/v1/tickets/ticket-comments',
  assignAgentTicket: '/api/v1/tickets/assign-ticket',
  updateStatusTicket: '/api/v1/tickets/change-ticket-status',
  updateTimeTicket: '/api/v1/tickets/set-ticket-time',
  updateReportedByTicket: '/api/v1/tickets/set-reportedby-ticket',
  updateIssuedByTicket: '/api/v1/tickets/set-issued-ticket',

  createElementCatalog: '/api/v1/catalogs/create-element',
  createUpdateElement: '/api/v1/catalogs/create-catalog-element',
  getAllElementCatalog: '/api/v1/catalogs/get-simple-catalog',
  getSpecificElementCatalog: '/api/v1/catalogs/get-element-catalog',
  editSpecificElementCatalog: '/api/v1/catalogs/edit-element',
  deleteSpecificElementCatalog: '/api/v1/catalogs/delete-element',

  // baseUrlFiles: 'http://localhost:4000', // local
  baseUrlFiles: 'https://api.rocinantehealthtech.com/tickets_api', // produccion
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
